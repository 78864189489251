import './styles.scss';

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function AjaxForm(name) {
  this.name = name;
  this.elements = [];
  this.domElement = document.querySelector('[name="' + name + '"]');
  this.action = this.domElement.getAttribute('action') || this.domElement.getAttribute('data-ajax-action');
  this.submitButton = this.domElement.querySelector('[name=submitForm]');
  this.defaultDebounceRateInMilliseconds = 0;
  this.debounceRateInMilliseconds = {
    keyup: 1000,
    blur: 500,
  };
}

AjaxForm.prototype = {
  constructor: AjaxForm,
  addElement: function (element) {
    this.elements.push(element);
    this.bindEvents(element);
  },
  getElementByName: function (name) {
    return this.elements.filter(function (element) {
      return element.getName() === name;
    });
  },
  bindEvents: function (element) {
    var domElement = element.domElement;
    var form = this;
    Array.prototype.forEach.call(element.getEventList(), function (eventName) {
      domElement.addEventListener(
        eventName,
        debounce(function () {
          form.validateElement(element);
        }, form.getDebounceRateForEvent(eventName))
      );
    });
  },
  submitButtonIsDisabled: function () {
    return (
      this.submitButton &&
      this.submitButton.disabled &&
      this.submitButton.getAttribute('data-enable-validation') !== 'true'
    );
  },
  validateElement: function (element) {
    if (this.action === null) {
      return;
    }
    if (this.submitButtonIsDisabled()) {
      return;
    }
    let domElement = element.domElement;
    let elementDataValue = element.getDomRepresentation().getAttribute('data-value');
    let elementValue = elementDataValue ? elementDataValue : element.getDomRepresentation().value;

    let elementConfig = JSON.parse(domElement.getAttribute('data-ajax'));
    if (
      elementConfig.hasOwnProperty('skipValidationWhenEmpty') &&
      elementConfig.skipValidationWhenEmpty === true &&
      elementValue === ''
    ) {
      element.resetElementClasses();
      return;
    }

    if (domElement.type === 'checkbox' && !domElement.checked) {
      elementValue = null;
    }

    let formParams = this.domElement.dataset.formParams;
    let ajaxCallUrl =
      this.action +
      (new URL(this.action, window.location).search.length === 0 ? '?' : '&') +
      'isAjaxForm=1' +
      '&formName=' +
      this.name +
      '&packageNumber=' +
      element.getNextPackageNumber() +
      '&elementName=' +
      element.getName() +
      '&elementValue=' +
      encodeURIComponent(elementValue);
    if (formParams) {
      ajaxCallUrl += '&formParams=' + formParams;
    }

    element.showSpinner();

    fetch(ajaxCallUrl)
      .then((response) => response.json())
      .then((data) => {
        let event;
        if (data.packageNumber !== element.getPackageNumber()) {
          return;
        }
        if (data.isValid) {
          element.markAsValid();
          event = new CustomEvent('success.validation.form');
        } else {
          element.markAsInvalid(data.message);
          event = new CustomEvent('error.validation.form');
        }
        domElement.dispatchEvent(event);
      })
      .finally(() => element.hideSpinner());
  },

  getDebounceRateForEvent: function (eventName) {
    return this.debounceRateInMilliseconds[eventName] || this.defaultDebounceRateInMilliseconds;
  },
};

function AjaxFormElement(element) {
  this.name = element.name;
  this.domElement = element;
  this.markElement = element;
  this.messageContainer = null;
  this.appendContainer = null;
  this.packageNumber = 0;
  this.hasLegacyStyles = !this.domElement.classList.contains('form-control');
  this.validationClasses = {};
  let currentElement = this.domElement;

  if (this.hasLegacyStyles) {
    this.validationClasses = {
      valid: 'input-success',
      invalid: 'input-error',
      validating: 'input-validating',
    };
    while ((currentElement = currentElement.nextElementSibling)) {
      if (currentElement.nodeName === 'UL') {
        this.messageContainer = currentElement.querySelector('li');
      }
    }
    if (this.messageContainer === null) {
      let messageContainerParent = document.createElement('ul');
      this.messageContainer = document.createElement('li');
      messageContainerParent.appendChild(this.messageContainer);
      this.domElement.parentNode.appendChild(messageContainerParent);
    }
  } else {
    this.validationClasses = {
      valid: 'is-valid',
      invalid: 'is-invalid',
      validating: 'is-indeterminate',
    };

    while ((currentElement = currentElement.nextElementSibling)) {
      if (currentElement.nodeName === 'DIV') {
        this.messageContainer = currentElement;
      }
    }
    if (this.messageContainer === null) {
      this.messageContainer = document.createElement('div');
      const parent = this.domElement.parentElement;
      if (parent?.classList.contains('intl-tel-input')) {
        const parentNextElement = parent.nextElementSibling;
        if (parentNextElement?.classList.contains('invalid-feedback')) {
          this.messageContainer = parentNextElement;
        } else {
          parent.after(this.messageContainer);
        }
      } else {
        this.domElement.after(this.messageContainer);
      }
    }

    if (this.messageContainer.classList.contains('input-group-append')) {
      this.appendContainer = this.messageContainer;
      this.messageContainer = document.createElement('div');
      this.appendContainer.after(this.messageContainer);
    }
  }

  if (this.domElement.hasAttribute('data-mark-element')) {
    // we can't set it right now because if it's initialized later with custom javascript the element may not
    // be in the DOM yet
    this.markElement = null;
  }
}

AjaxFormElement.prototype = {
  constructor: AjaxFormElement,
  getName: function () {
    return this.name;
  },
  setEventList: function (eventList) {
    this.eventList = eventList;
  },
  getEventList: function () {
    return this.eventList;
  },
  getDomRepresentation: function () {
    return this.domElement;
  },
  markAsValid: function () {
    if (this.hasLegacyStyles) {
      this.getMarkingElement().classList.add(this.validationClasses.valid);
      this.getMarkingElement().classList.remove(this.validationClasses.invalid);
    } else {
      if (!this.isMessageContainerSibling()) {
        this.messageContainer.classList.remove('d-block');
      }
      this.domElement.classList.add(this.validationClasses.valid);
      this.domElement.classList.remove(this.validationClasses.invalid);
    }
  },
  resetElementClasses: function () {
    if (this.hasLegacyStyles) {
      this.getMarkingElement().classList.remove(this.validationClasses.invalid, this.validationClasses.valid);
    } else {
      if (!this.isMessageContainerSibling()) {
        this.messageContainer.classList.remove('d-block');
      }
      this.domElement.classList.remove(this.validationClasses.invalid, this.validationClasses.valid);
    }
  },
  markAsInvalid: function (errorMessage) {
    if (errorMessage) {
      this.messageContainer.innerHTML = errorMessage;
    }
    if (this.hasLegacyStyles) {
      this.getMarkingElement().classList.add(this.validationClasses.invalid);
      this.getMarkingElement().classList.remove(this.validationClasses.valid);
    } else {
      if (!this.isMessageContainerSibling()) {
        this.messageContainer.classList.add('d-block');
      }
      this.messageContainer.classList.add('invalid-feedback');
      this.domElement.classList.add(this.validationClasses.invalid);
      this.domElement.classList.remove(this.validationClasses.valid);
    }
  },
  showSpinner: function () {
    if (this.hasLegacyStyles) {
      this.getMarkingElement().classList.add(this.validationClasses.validating);
    } else {
      this.domElement.classList.add(this.validationClasses.validating);
    }
  },
  hideSpinner: function () {
    if (this.hasLegacyStyles) {
      this.getMarkingElement().classList.remove(this.validationClasses.validating);
    } else {
      this.domElement.classList.remove(this.validationClasses.validating);
    }
  },
  isMessageContainerSibling: function () {
    return this.messageContainer.previousElementSibling.classList.contains('form-control');
  },
  getNextPackageNumber: function () {
    return ++this.packageNumber;
  },
  getPackageNumber: function () {
    return this.packageNumber;
  },
  getMarkingElement: function () {
    if (!this.markElement) {
      let query = this.domElement.getAttribute('data-mark-element');
      this.markElement = this.domElement.closest(query);
      if (!this.markElement) {
        this.markElement = this.domElement;
      }
    }
    return this.markElement;
  },
};

function initAjaxForms(rootNode) {
  let attributeKey = 'data-ajax';

  Array.prototype.forEach.call(rootNode.querySelectorAll('[' + attributeKey + ']'), function (element) {
    let formElement = new AjaxFormElement(element);
    let formName = element.form.getAttribute('name');
    let elementConfig = JSON.parse(element.getAttribute(attributeKey));
    formElement.setEventList(elementConfig.eventList);
    if (!appForms[formName]) {
      appForms[formName] = new AjaxForm(formName);
    }
    appForms[formName].addElement(formElement);
  });
}

window.appForms = [];

window.anw = window.anw || {};
window.anw.initAjaxForms = initAjaxForms;

document.addEventListener('DOMContentLoaded', function () {
  initAjaxForms(document);
});
